import React, { useState } from "react";

import './login.css';

import { supabase } from '../../utils/Supabase';

function Login() {
  const [email, setEmail] = useState('');
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  async function loginWithGoogle() {
    await supabase.auth.signIn({
      provider: 'google',
    })
  }

  async function loginWithEmail() {
    if (!email) { return; }

    const { error } = await supabase.auth.signIn({
      email: email,
    });

    if (!error) {
      setMagicLinkSent(true);
    }
  }

  return (
    <>
      <div id="gSignInWrapper">
        {magicLinkSent &&
          <p>Magic link sent.</p>
        }

        {!magicLinkSent &&
          <>
            <input type="text" className="shadow appearance-none border rounded py-1 px-1 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} /> <br />
            <div id="customBtn" className="customGPlusSignIn mb-2 px-1" onClick={loginWithEmail}>
              <p className="buttonText">Login</p>
            </div><br />
          </>
        }

        <div id="customBtn" className="customGPlusSignIn" onClick={loginWithGoogle}>
          <span className="icon"></span>
          <span className="buttonText">Login with Google</span>
        </div>
      </div>
    </>
  )
}

export default Login
