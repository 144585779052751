import { createReducer } from '@reduxjs/toolkit'
import { setOpenTab } from './actions'

export interface DashboardState {
  isOpenTab: string
}

const initialState: DashboardState = {
  isOpenTab: 'Swap'
}

export default createReducer<DashboardState>(initialState, builder =>
  builder.addCase(setOpenTab, (state, action) => {
    state.isOpenTab = action.payload.isOpenTab
  })
)
