import gql from "graphql-tag"

// TOMB-FTM
// TOMB-MAI
// BASED-MAI
// BASED-TOMB

const TOKEN_ADDRESSES = {
  tomb: "0x6c021ae822bea943b2e66552bde1d2696a53fbb7",
  based: "0x8d7d3409881b51466b483b11ea1b8a03cded89ae",
  ftm: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  mai: "0xfb98b335551a418cd0737375a2ea0ded62ea213b",
}

const EXCHANGE_ADDRESSES = {
  tombswap: "0xc72ef1bae14d0be7ec28c149e5e24659115f7c90",
  spookyswap: "0x228ffd7122d202c0cd24330892881c47b0817c7a",
}

export const GET_ORDERS = (token1, token2, exchange) => {
  const token1fortoken2WhereInput =
    token1 === "all"
      ? `inputToken_in: ["${TOKEN_ADDRESSES.tomb}", "${TOKEN_ADDRESSES.based}", "${TOKEN_ADDRESSES.ftm}", "${TOKEN_ADDRESSES.mai}"]`
      : `inputToken: "${TOKEN_ADDRESSES[token1]}"`

  const token1fortoken2WhereOutput =
    token2 === "all"
      ? `outputToken_in: ["${TOKEN_ADDRESSES.tomb}", "${TOKEN_ADDRESSES.based}", "${TOKEN_ADDRESSES.ftm}", "${TOKEN_ADDRESSES.mai}"]`
      : `outputToken: "${TOKEN_ADDRESSES[token2]}"`

  const token2fortoken1WhereInput =
    token2 === "all"
      ? `inputToken_in: ["${TOKEN_ADDRESSES.tomb}", "${TOKEN_ADDRESSES.based}", "${TOKEN_ADDRESSES.ftm}", "${TOKEN_ADDRESSES.mai}"]`
      : `inputToken: "${TOKEN_ADDRESSES[token2]}"`

  const token2fortoken1WhereOutput =
    token1 === "all"
      ? `outputToken_in: ["${TOKEN_ADDRESSES.tomb}", "${TOKEN_ADDRESSES.based}", "${TOKEN_ADDRESSES.ftm}", "${TOKEN_ADDRESSES.mai}"]`
      : `outputToken: "${TOKEN_ADDRESSES[token1]}"`

  const handlerWhere =
    exchange === "all"
      ? `handler_in: ["${EXCHANGE_ADDRESSES.tombswap}", "${EXCHANGE_ADDRESSES.spookyswap}"]`
      : `handler: "${EXCHANGE_ADDRESSES[exchange]}"`

  return gql`
    {
      token1fortoken2: orders(
        first: 1000
        where: {
          status: "open"
          ${token1fortoken2WhereInput}
          ${token1fortoken2WhereOutput}
          ${handlerWhere}
        }
        orderBy: updatedAt
        orderDirection: desc
      ) {
        id
        owner
        createdTxHash
        inputToken
        outputToken
        inputAmount
        minReturn
        status
        handler
        createdAt
      }
      token2fortoken1: orders(
        first: 1000
        where: {
          status: "open"
          ${token2fortoken1WhereInput}
          ${token2fortoken1WhereOutput}
          ${handlerWhere}
        }
        orderBy: updatedAt
        orderDirection: desc
      ) {
        id
        owner
        createdTxHash
        inputToken
        outputToken
        inputAmount
        minReturn
        status
        handler
        createdAt
      }
    }
  `
}

export const GET_TOKEN_PRICES = gql`
  {
    tokens {
      id
      symbol
      name
      tokenDayData(first: 1, orderBy: date, orderDirection: desc) {
        id
        date
        priceUSD
      }
    }
  }
`

export const GET_NETWORK = gql`
    {
      networks(
        first: 1000
        orderBy: updatedAt
        orderDirection: desc
        subgraphError: allow
      ) {
        id
        interval
        lastTx
        totalTx
        updatedAt
      }
    }
`
