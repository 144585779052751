import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('swap/selectCurrency')
export const switchCurrencies = createAction<void>('swap/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('swap/typeInput')
export const typeInputLimit = createAction<{ typedValue: string; percentage?: string }>('swap/typeInputLimit')
export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  limitValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('swap/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient')
export const typeDeviation = createAction<{ deviation: string }>('swap/typeDeviation')
export const typeOrdersAmount = createAction<{ ordersAmount: string }>('swap/typeOrdersAmount')
export const setLimitOrders = createAction<{
  orders: {
    inputToken: string
    outputToken: string
    inputAmount: string
    minReturn: string
    adjustedMinReturn: string
  }[]
}>('swap/setLimitOrders')
