import React, { useState } from 'react'
import DashboardSwapContent from '../../components/swap/DashboardSwapContent'
import { useGelatoLimitOrdersHistory } from '@gelatonetwork/limit-orders-react'
import Web3Status from '../../components/Web3Status'
import { useSetOpenTab } from '../../state/dashboard/hooks'

export enum OrdersTab {
  LIMIT_ORDERS = 'LIMIT_ORDERS',
  OPEN_ORDERS = 'OPEN_ORDERS',
  PAST_ORDERS = 'PAST_ORDERS',
  LIQUIDITY = 'LIQUIDITY'
}

function LimitOrders() {
  const [openTab, setOpenTab] = useState(OrdersTab.LIMIT_ORDERS)
  const [openSideTab] = useSetOpenTab()
  const { open } = useGelatoLimitOrdersHistory()
  const totalOpenOrders = [...open.pending, ...open.confirmed].length

  return (
    <>
      {/* <GelatoLimitOrdersHistoryPanel /> */}
      <div className={`dark:bg-bgBlack bg-bgColor pt-1 block`}>
        {/* <div className={`flex justify-around pb-5`}>
          <div className="font-semibold px-2">
            TOMB/FTM <span className="font-light pl-1">0.97542</span>
          </div>
          <div>|</div>
          <div className="font-semibold px-2">
            TOMB/MAI <span className="font-light pl-1">1.65462</span>
          </div>
          <div>|</div>
          <div className="font-semibold px-2">
            BASED/TOMB <span className="font-light pl-1">1.65462</span>
          </div>
          <div>|</div>
          <div className="font-semibold px-2">
            BASED/MAI <span className="font-light pl-1">1.65462</span>
          </div>
          <div>|</div>
          <div className="font-semibold px-2">
            TSHARE/FTM <span className="font-light pl-1">3215.65452</span>
          </div>
          <div>|</div>
          <div className="font-semibold px-2">
            TSHARE/MAI <span className="font-light pl-1">3215.65452</span>
          </div>
          <div className="font-semibold px-2">
            TSHARE/MAI <span className="font-light pl-1">3215.65452</span>
          </div>
        </div> */}
        {openSideTab === 'Swap' && (
          <div className="w-full flex">
            <div className="grid grid-cols-4 w-3/5 px-4 gap-4">
              <div
                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${
                  openTab === OrdersTab.LIMIT_ORDERS ? 'bg-white dark:bg-bgGray' : ''
                }`}
                onClick={() => setOpenTab(OrdersTab.LIMIT_ORDERS)}
              >
                <p>Limit Orders</p>
              </div>
              <div
                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${
                  openTab === OrdersTab.OPEN_ORDERS ? 'bg-white dark:bg-bgGray' : ''
                }`}
                onClick={() => setOpenTab(OrdersTab.OPEN_ORDERS)}
              >
                <p>Open Orders - {totalOpenOrders}</p>
              </div>
              <div
                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${
                  openTab === OrdersTab.PAST_ORDERS ? 'bg-white dark:bg-bgGray' : ''
                }`}
                onClick={() => setOpenTab(OrdersTab.PAST_ORDERS)}
              >
                <p>Past Orders</p>
              </div>
              {/* <div
                className={`flex justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${
                  openTab === OrdersTab.LIQUIDITY ? 'bg-white dark:bg-bgGray' : ''
                }`}
                onClick={() => setOpenTab(OrdersTab.LIQUIDITY)}
              >
                <p>Liquidity</p>
              </div> */}
            </div>
            <div className="flex justify-center ml-auto dark:text-lightGray">
                <Web3Status />
              </div>
          </div>
        )}
      </div>
      <div className={`flex ${openSideTab === 'Swap' ? 'active-swap' : 'active-orders'}`}>
        <DashboardSwapContent openTab={openTab} />
      </div>
    </>
  )
}

export default LimitOrders
