import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import application from './application/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import dashboard from './dashboard/reducer'
import { gelatoReducers, GELATO_PERSISTED_KEYS } from '@gelatonetwork/limit-orders-react'
import { gelatoStopLimitReducers, GELATO_STOPLIMIT_PERSISTED_KEYS } from '@gelatonetwork/stop-limit-orders-react'
import { gelatoRangeOrderReducers, GELATO_RANGE_PERSISTED_KEYS } from '@gelatonetwork/range-orders-react'

const PERSISTED_KEYS: string[] = [
  'user',
  'transactions',
  ...GELATO_PERSISTED_KEYS,
  ...GELATO_STOPLIMIT_PERSISTED_KEYS,
  ...GELATO_RANGE_PERSISTED_KEYS
]

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
    dashboard,
    ...gelatoReducers,
    ...gelatoStopLimitReducers,
    ...gelatoRangeOrderReducers
  },
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    save({ states: PERSISTED_KEYS })
  ],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
