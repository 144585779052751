import { Currency, Pair } from 'tombswap-sdk'
import React, { useState, useContext, useCallback } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencySearch } from '../SearchModal/CurrencySearch'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import ReactGA from 'react-ga'

import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { useIsDarkMode } from '../../state/user/hooks'

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`

const CurrencySelect = styled.button<{ selected: boolean; isDark: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  color: ${({ isDark, theme }) => (isDark ? theme.white : theme.black)};
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
`
// background-color: ${({ selected, theme }) => (selected ? theme.bg1 : theme.primary1)};
// background-color: ${({ selected, theme }) => (selected ? 'transparent' : theme.primary1)};

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.primary5};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;
  text-align: right;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border: none;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  border: 1px solid #cecece;
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '20px' : '16px')};

`

const StyledBalanceMax = styled.button`
  height: 21px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.3rem;
  font-size: 0.75rem;

  font-weight: 600;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.white};
  :hover {
    background-color: rgba(19, 54, 98, 0.44);
    color: #fff2d1 !important;
  }
  :focus {
    background-color: rgba(19, 54, 98, 0.44);
    color: #fff2d1 !important;
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput?: (value: string) => void
  onMax?: () => void
  onHalf?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  disableInput?: boolean
  otherCurrency?: Currency | null
  id: string
  isButton?: boolean
  showCommonBases?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  onHalf,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  disableInput,
  otherCurrency,
  id,
  isButton = false,
  showCommonBases
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()

  const [dropdown, setDropdown] = useState(false)
  const { account } = useActiveWeb3React()

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const theme = useContext(ThemeContext)

  const isDark = useIsDarkMode()
  const handleDismissSearch = useCallback(() => {
    setDropdown(false)
  }, [setDropdown])

  const handleClickChangeList = useCallback(() => {
    ReactGA.event({
      category: 'Lists',
      action: 'Change Lists'
    })
  }, [])

  function setDropdownState() {
    setDropdown(!dropdown)
  }
  function closeDropdownState() {
    setDropdown(false)
  }
  return (
    <InputPanel className="" id={id}>
      <Container
        className="bg-white dark:bg-darkButtonBackground  h-10 px-2 border-2 w-full"
        style={{ width: '170px' }}
        hideInput={hideInput}
      >
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
                {label}
              </TYPE.body>
              {account && (
                <TYPE.body
                  color={theme.text2}
                  fontWeight={500}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? 'Balance: ' + selectedCurrencyBalance?.toSignificant(3)
                    : ' -'}
                  {account && currency && showMaxButton && label !== 'To' && (
                    <>
                      <StyledBalanceMax className="max-btn fifty-percent pl-2 pr-2" onClick={onHalf}>
                        50%
                      </StyledBalanceMax>
                      <StyledBalanceMax className="max-btn pl-2 pr-2" onClick={onMax}>
                        MAX
                      </StyledBalanceMax>
                    </>
                  )}
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input token-amount-value"
                value={value}
                onUserInput={val => {
                  onUserInput && onUserInput(val)
                }}
                isDisabled={disableInput}
              />
            </>
          )}
          <CurrencySelect
            selected={!!currency}
            isDark={!!isDark}
            className={'open-currency-select-button ' + (!!isButton ? 'btn' : '')}
            onClick={() => {
              if (!disableCurrencySelect) {
                setDropdownState()
              }
            }}
            onBlur={() => {
              if (!disableCurrencySelect) {
                closeDropdownState()
              }
            }}
          >
            <Aligner className="flex justify-between">
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={'24px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.symbol) || t('selectToken')}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
            {!disableCurrencySelect && onCurrencySelect && (
              // <CurrencySearchModal
              //   isOpen={dropdown}
              //   onDismiss={handleDismissSearch}
              //   onCurrencySelect={onCurrencySelect}
              //   selectedCurrency={currency}
              //   otherSelectedCurrency={otherCurrency}
              //   showCommonBases={showCommonBases}
              // />
              <CurrencySearch
                isOpen={dropdown}
                onDismiss={handleDismissSearch}
                onCurrencySelect={onCurrencySelect}
                onChangeList={handleClickChangeList}
                selectedCurrency={currency}
                otherSelectedCurrency={otherCurrency}
                showCommonBases={showCommonBases}
              />
            )}
          </CurrencySelect>
        </InputRow>
      </Container>
    </InputPanel>
  )
}
