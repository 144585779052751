import { createReducer } from '@reduxjs/toolkit'
import { TOMB } from '../../constants'
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setLimitOrders,
  setRecipient,
  switchCurrencies,
  typeDeviation,
  typeInput,
  typeInputLimit,
  typeOrdersAmount
} from './actions'

export interface SwapState {
  readonly independentField: Field
  readonly typedValue: string
  readonly limitValue: string
  readonly percentage?: string
  readonly deviation?: string
  readonly ordersAmount?: string

  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null
  readonly limitOrders: {
    inputToken: string
    outputToken: string
    inputAmount: string
    minReturn: string
    adjustedMinReturn: string
  }[]
}

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: 'ETH'
  },
  [Field.OUTPUT]: {
    currencyId: TOMB.address
  },
  limitValue: '',
  percentage: '',
  deviation: '0',
  ordersAmount: '1',
  limitOrders: [],
  recipient: null
}

export default createReducer<SwapState>(initialState, builder =>
  builder
    .addCase(
      replaceSwapState,
      (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId, limitValue } }) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId
          },
          independentField: field,
          typedValue: typedValue,
          limitValue,
          recipient,
          deviation: '',
          limitOrders: []
        }
      }
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId: currencyId },
          [otherField]: { currencyId: state[field].currencyId }
        }
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId }
        }
      }
    })
    .addCase(switchCurrencies, state => {
      if (state.limitValue) {
        return {
          ...state,
          typedValue: '',
          limitValue: '',
          percentage: '',
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
          [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId }
        }
      }
      return {
        ...state,
        independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId }
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue
      }
    })
    .addCase(typeInputLimit, (state, { payload: { typedValue, percentage } }) => {
      return {
        ...state,
        limitValue: typedValue,
        percentage: percentage
      }
    })
    .addCase(typeDeviation, (state, { payload: { deviation } }) => {
      return {
        ...state,
        deviation
      }
    })
    .addCase(typeOrdersAmount, (state, { payload: { ordersAmount } }) => {
      return {
        ...state,
        ordersAmount
      }
    })
    .addCase(setLimitOrders, (state, { payload: { orders } }) => {
      return {
        ...state,
        limitOrders: orders
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
)
