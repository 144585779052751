import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"

export const client = new ApolloClient({
  link: new HttpLink({
    uri:
      "https://api.thegraph.com/subgraphs/name/gelatodigital/limit-orders-fantom-ii",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const priceClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.thegraph.com/subgraphs/name/jamjomjim/tomb-finance",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})


export const networkClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.thegraph.com/subgraphs/name/ruvlol/network",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})
