import React from "react";
import { OrdersView } from "../OrdersView/OrdersView"

function Dashboard() {
  return (
    <OrdersView />
  )
}

export default Dashboard
