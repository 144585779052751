import React from "react"
import styled from "styled-components"
import { numberWithCommas } from "../../utils/utils"
import { useIsWhiteList } from '../../state/user/hooks'
import {getFormattedTime} from '../../utils/utils'
import { useIsDarkMode } from "../../state/user/hooks"

const TOKEN_NAMES = {
  "0x6c021ae822bea943b2e66552bde1d2696a53fbb7": "TOMB",
  "0x8d7d3409881b51466b483b11ea1b8a03cded89ae": "Based",
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee": "FTM",
  "0xfb98b335551a418cd0737375a2ea0ded62ea213b": "MAI",
}

const EXCHANGES = {
  "0xc72ef1bae14d0be7ec28c149e5e24659115f7c90": "TombSwap",
  "0x228ffd7122d202c0cd24330892881c47b0817c7a": "SpookySwap",
}

// These values are hardcoded within gelato's limit orders lib
// https://github.com/gelatodigital/limit-orders-lib/blob/c99066999b5731a6be90450d94ba0cbfd1c9234d/packages/limit-orders-lib/src/constants/index.ts
const SLIPPAGE_BPS = 40 // 0.4%

// This value depends on the limit order version you're using...
// SpookySwap seems to use
// https://github.com/gelatodigital/limit-orders-lib/blob/c99066999b5731a6be90450d94ba0cbfd1c9234d/packages/limit-orders-lib/src/constants/index.ts
// TombSwap seems to use
// https://github.com/gelatodigital/swap-orders-lib/blob/master/packages/limit-orders-lib/src/constants/index.ts
const SS_BPS_GELATO_FEE = 2
const TS_BPS_GELATO_FEE = 10

// const Row = styled.div`
//   display: flex;
//   background-color: ${(props) =>
//     props.isBuy ? "#2A2A2A" : props.isSell ? "#2A2A2A" : "unset"};
// `

// const Item = styled.div`
//   flex: 1;
//   min-width: auto;
//   text-align: center;
//   padding: 5px;
//   border: 1px solid rgba(206, 206, 206, 0.1);
// `

const format18 = (amount) => {
  return (amount / 1e18).toFixed(2)
}

export const OrderRow = ({
  token1,
  token2,
  order,
  prices,
  showExchange,
  showUnits,
  showMinPrice,
  address,
  index,
}) => {
  const isDark = useIsDarkMode()
  const WhiteListedAddress = useIsWhiteList();
  const isBuy =
    token1 === TOKEN_NAMES[order.inputToken].toLowerCase() ||
    token2 === TOKEN_NAMES[order.outputToken].toLowerCase()
  const isSell =
    token1 === TOKEN_NAMES[order.outputToken].toLowerCase() ||
    token2 === TOKEN_NAMES[order.inputToken].toLowerCase()

  const dollarPrice = prices[TOKEN_NAMES[order.inputToken].toLowerCase()]

  const gelatoFee =
    order.handler === "0xc72ef1bae14d0be7ec28c149e5e24659115f7c90"
      ? TS_BPS_GELATO_FEE
      : SS_BPS_GELATO_FEE
  const tokenPrice = showMinPrice
    ? order.minReturn / order.inputAmount
    : order.minReturn /
    order.inputAmount /
    (1 - (SLIPPAGE_BPS + gelatoFee) / 10000)
  const inverseTokenPrice = 1 / tokenPrice

  const isOdd = index && index % 2 === 1
  const padding = !showExchange ? 'px-5': 'px-1 text-center'
  const borderRight = 'border-r border-r-slate-300'

  return (
    <tr
    className={`text-left justify-center py-2 ${isOdd ? 'bg-bgColor dark:bg-liveOrderBg':''}`}
      // style={{ backgroundColor: isOdd ? isDark ? "#303030" : "" :  }}
      // isBuy={isBuy}
      // isSell={isSell}
    >
      <td className={`${padding} py-2 ${borderRight}`}>
        {tokenPrice.toFixed(4)}{" "}
        {showUnits &&
          `${TOKEN_NAMES[order.outputToken]}/${TOKEN_NAMES[order.inputToken]}`}
      </td>
      <td className={`${padding} py-2 ${borderRight}`}>
        {inverseTokenPrice.toFixed(4)}{" "}
        {showUnits &&
          `${TOKEN_NAMES[order.inputToken]}/${TOKEN_NAMES[order.outputToken]}`}
      </td>
      <td className={`${padding} py-2 ${borderRight}`}>
        {numberWithCommas(format18(order.inputAmount))}{" "}
        {TOKEN_NAMES[order.inputToken]}
      </td>

      <td className={`${padding} py-2 ${borderRight}`}>
        {(format18(order.inputAmount) * dollarPrice).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </td>

      <td className={`${padding} py-2 ${borderRight}`}>
        {numberWithCommas(format18(order.minReturn))}{" "}
        {TOKEN_NAMES[order.outputToken]}
      </td>

      {showExchange && <td className={`${padding} py-2 ${borderRight}`}>{EXCHANGES[order.handler] ?? "Unknown"}</td>}

      <td className={`${padding} py-2 ${borderRight}`}>
        <a
          href={`https://ftmscan.com/tx/${order.createdTxHash}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline" }}
        >
          TX
        </a>
        &nbsp; | &nbsp;
        <a
          href={`https://ftmscan.com/address/${order.owner}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline" }}
        >
          Wallet
        </a>
        &nbsp; | &nbsp;
        <a
          href={`https://debank.com/profile/${order.owner}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline" }}
        >
          DE
        </a>
      </td>
      <td className={`${padding} py-2 ${borderRight}`}>
        {WhiteListedAddress?.[order.owner.toLowerCase()]?.name ?? 'Unknown'}
      </td>
      <td className={`${padding} py-2 ${borderRight}`}>{getFormattedTime(order.createdAt)}</td>
    </tr>
  )
}