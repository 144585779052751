import React, { useEffect, useState } from 'react'
import AccountsTable from '../../components/AccountDetails/AccountsTable'
export default function Dashboard(){

    return (
        <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
             <AccountsTable />
            </div>
          </div>
        </div>
      </div>
    )
}