import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable'
import { networkClient } from '../../apollo/client'
import {GET_NETWORK} from '../../apollo/queries'

export default function Bots(){

  const [bots, setBots] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  
  useEffect( ()=>{
    
    const getNetwork = async () => {
      if (isLoading) return
      setIsLoading(true)
      const result = await networkClient.query({
        query: GET_NETWORK,
      })
      
      setBots(result.data.networks)
      setIsLoading(false)
      networkClient.resetStore()
    }

    const Interval = setInterval( () =>{
      getNetwork();
    }, 5000)

    getNetwork()

    return () => clearInterval(Interval)

  },[])
    return (
        <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
              <DataTable botNetworks={bots} />
            </div>
          </div>
        </div>
      </div>
    )
}