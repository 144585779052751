import { AppDispatch, AppState } from '../index'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenTab } from './actions'


export function useIsOpenTab(): string  {
  return useSelector<AppState, AppState['dashboard']['isOpenTab']>(state => state.dashboard.isOpenTab)
}

export function useSetOpenTab(): [string, (isOpenTab: string) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const openTab = useIsOpenTab()
  const setTab =  useCallback(
    (isOpenTab: string) => {
      dispatch(setOpenTab({isOpenTab}))
    },
    [dispatch]
  )
  return [openTab, setTab]
}