import { Handler } from '@gelatonetwork/limit-orders-react'
import { createAction } from '@reduxjs/toolkit'

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}
export interface WhiteListData {
  index: number
  address: string
  name: string
}

export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')
export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode')

export const updateUserWhiteList = createAction<{ index: number; address: string; name: string }>(
  'user/updateUserWhiteList'
)
export const deleteUserWhiteList = createAction<{ address: string; name: string }>('user/deleteUserWhiteList')
export const clearUserLocalWhiteList = createAction('user/clearUserLocalWhiteList')

export const deleteUserBotWhiteList = createAction<{ index: number }>('user/deleteUserBotWhiteList')
export const clearUserLocalBotWhiteList = createAction('user/clearUserLocalBotWhiteList')

export const updateUserBotWhiteList = createAction<{
  index: number,
  name: any,
  owner: any,
  description: any,
  contract_address: any,
  bot_address: any
}>(
  'user/updateUserBotWhiteList'
)

export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>('user/updateUserExpertMode')
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number }>(
  'user/updateUserSlippageTolerance'
)
export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline')
export const addSerializedToken = createAction<{ serializedToken: SerializedToken }>('user/addSerializedToken')
export const removeSerializedToken = createAction<{ chainId: number; address: string }>('user/removeSerializedToken')
export const addSerializedPair = createAction<{ serializedPair: SerializedPair }>('user/addSerializedPair')
export const removeSerializedPair = createAction<{ chainId: number; tokenAAddress: string; tokenBAddress: string }>(
  'user/removeSerializedPair'
)

export const setGelatoHandler = createAction<{ handler: Handler }>('user/setGelatoHandler')
