import React from 'react';
import {tokens} from '../../tokens.json'
import {useTokenBalance} from '../../state/wallet/hooks'
import {ChainId, Token} from 'tombswap-sdk'
import { numberWithCommas } from "../../utils/utils"
import {
    useIsWhiteList,
  } from "../../state/user/hooks";

const AccountsTable = () => {

   const Wallets = useIsWhiteList()
    const tokensAddresses = tokens.map( token => {
        return new Token(ChainId.MAINNET, token.address, token.decimals, token.symbol, token.name);
    } )

    const Balances = [
        {token:'FTM', wallet1:1500, wallet2:1400, wallet3:1400, wallet4:1400, wallet5:1400, wallet6:1400, wallet7:7990},
        {token:'TOMB', wallet1:1500, wallet2:1400, wallet3:1400, wallet4:1400, wallet5:1400, wallet6:1400, wallet7:7990},
        {token:'TSHARE', wallet1:1500, wallet2:1400, wallet3:1400, wallet4:1400, wallet5:1400, wallet6:1400, wallet7:7990},
        {token:'BASED', wallet1:1500, wallet2:1400, wallet3:1400, wallet4:1400, wallet5:1400, wallet6:1400, wallet7:7990},
        {token:'ßSHARE', wallet1:1500, wallet2:1400, wallet3:1400, wallet4:1400, wallet5:1400, wallet6:1400, wallet7:7990},
        {token:'MAI', wallet1:1500, wallet2:1400, wallet3:1400, wallet4:1400, wallet5:1400, wallet6:1400, wallet7:7990},
    ]
    
    if(!Object.keys(Wallets).length){
        return (
            <div className="flex justify-center text-center p-20">
                loading
            </div>
        )
    }

    return (
        <table className="min-w-full divide-y divide-gray-500">
            <thead className="bg-black text-center">
                <tr className="text-center">
                    <th scope="col" className="py-2 text-sm font-semibold text-lightGray">Token</th>
                    {
                        Object.keys(Wallets).map(address => {
                            return <th key={address} scope="col" className="py-2 text-sm font-semibold text-lightGray">{ Wallets[address].name }</th>
                        })
                    }
                </tr>
            </thead>
            <tbody className="bg-black divide-y divide-gray-500 text-center">
                {
                    tokensAddresses.map((token,index) => 
                        {
                            return <BalanceRow key={index} wallets={Wallets} token={token} />
                    })
                }

            </tbody>
        </table>
    )
}

const BalanceRow = ({wallets, token}:{wallets:any, token:Token}) => {
    
    return (
            <tr>
                <td className="py-1.5 whitespace-nowrap text-sm font-medium text-lightGray">{token?.symbol || '--' }</td>
                {
                    Object.keys(wallets).map((wallet) => {
                        return <BalanceCol key={wallet} wallet={wallet} token={token} />
                    })
                }
            </tr>
    )
}

const BalanceCol = ({wallet, token}:{wallet:any, token:Token}) => {
   let address = wallet
    if(wallet.indexOf('delete')){
        address = wallet.replace("delete",'')
    }
    const balance = useTokenBalance(address,token)
    return (
        <td className="py-1.5 whitespace-nowrap text-sm text-lightGray">{ numberWithCommas(balance?.toSignificant(2)||'0') }</td>
    )
}

export default AccountsTable;
