import {getFormattedTime, getTimer, numberWithCommas} from '../utils/utils'
import {useETHBalances, useTokenBalance} from '../state/wallet/hooks'
import ERC20_INTERFACE from '../constants/abis/erc20'
import {  useMultipleContractSingleData } from '../state/multicall/hooks'
import {shortenText} from '../utils'
import Bots from '../bots.json'
const DataTable = ({botNetworks}) => {

   const botsDetailsHash = {}
   for (let i=0; i<Bots.bots.length; i++) {
       if(Bots.bots[i]?.contractAddress){
        botsDetailsHash[Bots.bots[i]?.contractAddress.toLowerCase()] = Bots.bots[i]
       }
   }

    return (
        <table className="min-w-full divide-y divide-gray-500">
            <thead className="bg-black">
                <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-lightGray sm:pl-6 text-center">Name</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Owner</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Function</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Contact Balance</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Bot Balance</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Timer</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Interval</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Last Tx Timestamp</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Last Tx Link</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center">Last Tx State</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-lightGray text-center"># of Transactions</th>
                </tr>
            </thead>
            <tbody className="bg-black divide-y divide-gray-500">
                {
                    botNetworks.map((item, index) => (
                       <TableItems key={item.id} item={item} botsDetailsHash={botsDetailsHash} />
                    ))
                }

            </tbody>
        </table>
    )
}

export const TableItems = ({item, botsDetailsHash}) => {
    let botAddress = botsDetailsHash[item.id.toLowerCase()]?.botAddress.split(" ")[0]
    const BotEtherBalance = useETHBalances([botAddress ?? undefined])
    const BotBalance = BotEtherBalance?.[Object.keys(BotEtherBalance)[0]]? numberWithCommas(BotEtherBalance?.[Object.keys(BotEtherBalance)[0]]?.toSignificant(6)) : 0
    // const balances = useMultipleContractSingleData([item.id.toLowerCase()], ERC20_INTERFACE, 'balanceOf', [botAddress])
    // const contractBalance = useTokenBalance(botAddress, item.id.toLowerCase())
    // console.log("contract Balance", balances)

    return (
        <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-lightGray sm:pl-6">{botsDetailsHash?.[item.id.toLowerCase()]?.ownerName || '--'}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{botsDetailsHash[item.id.toLowerCase()]?.ownerName || '--'}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{shortenText(botsDetailsHash[item.id.toLowerCase()]?.funtion, 20) || '--'}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{item?.contactBalance || '--'}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{ BotBalance || '--'}</td>
        <td className='whitespace-nowrap text-sm text-lightGray'>
            <div style={{width: `${ Number(getTimer(item)) > 0 ? (( Number(getTimer(item)) / Number(item.interval) ) * 100 ) : 0}%`}} className='bg-Timer'>
                {getTimer(item)}
            </div>

        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{item?.interval}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{getFormattedTime(item?.updatedAt)}</td>
        <td className=" cursor-pointer underline whitespace-nowrap px-3 py-4 text-sm text-lightGray">
            <a target='_blank' href={`https://ftmscan.com/tx/${item.lastTx}`}>TX</a>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{'Success' || '--'}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-lightGray">{item?.totalTx}</td>
    </tr>
    )

}

export default DataTable;