import { Token, WETH } from 'tombswap-sdk'
import { getTokenLogoURL } from '../components/CurrencyLogo'
import { BASED, BSHARE, FUSDT, MAI, TBOND, TOMB, TSHARE, USDC, WBTC } from '../constants'

/**
 * Add token to wallet
 * @returns {boolean} true if token added succesfully, false if provider returns error
 */

export const addTokenToWallet = async (token: Token) => {
  const provider = window.ethereum as any

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            image: getTokenLogoURL(false, token.symbol)
          }
        }
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't add token to wallet because window.ethereum is undefined")
    return false
  }
}

export const batchAddTokens = async () => {
  const tokensToAdd = [MAI, TSHARE, TOMB, USDC, BASED, TBOND, BSHARE, FUSDT, WBTC, WETH[250]]

  const requests = tokensToAdd.map(async token => {
    return await addTokenToWallet(token)
  })

  await Promise.all(requests)
}
