import moment from "moment";
export function numberWithCommas(x: any) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}


export const getFormattedTime = (time:string,locale = 'en-US') => {
    return `${moment(new Date(parseInt(time + '000'))).format('MM/DD/YYYY HH:mm:ss')}`
    // const formattedDate = parsedTime.toLocaleDateString(locale, {
    //   year: 'numeric',
    //   month: '2-digit',
    //   day: '2-digit'
    // })
    // const formattedTime = parsedTime.toLocaleTimeString(locale)
  }

  export const getTimer = (item:any) => {
    const parsedTime = new Date(parseInt(item.updatedAt + '000'))
    let interval = moment(parsedTime).add(item.interval, 's'),
    timeNow = moment()
    var duration = moment.duration(interval.diff(timeNow));
    return Math.floor(duration.asSeconds());
  }