import { Currency, CurrencyAmount } from 'tombswap-sdk'
import ReactSelect from 'react-select'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import React, { useCallback, useState } from 'react'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { Field } from '../../state/swap/actions'
import { useDerivedSwapInfo, useSetLimitOrdersBatch, useSwapActionHandlers, useSwapState } from '../../state/swap/hooks'

import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks'
import { GelatoLimitOrders, ChainId } from '@gelatonetwork/limit-orders-lib'
import { MaxUint256 } from '@ethersproject/constants'
import { Handler } from '@gelatonetwork/limit-orders-react'
import { useGetGelatoHandler, useSetGelatoHandler } from '../../state/user/hooks'
import clsx from 'clsx'
import Big from 'big.js'
import { batchAddTokens } from '../../utils/addTokenToWallet'

export default function Swap() {
  const { account, library, chainId } = useActiveWeb3React()

  const { independentField, typedValue, limitValue, percentage, deviation, ordersAmount, limitOrders } = useSwapState()
  const { v2Trade, currencyBalances, parsedAmount, currencies } = useDerivedSwapInfo()
  const trade = v2Trade

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
    [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
  }

  const setGelatoHandler = useSetGelatoHandler()

  const {
    onCurrencySelection,
    onUserInput,
    onUserLimitInput,
    onUserDeviationInput,
    onUserOrdersAmountInput
  } = useSwapActionHandlers()
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  useSetLimitOrdersBatch(trade, parsedAmount, currencies)

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])

  const [limitChanged, setLimitChanged] = useState(false)

  const gelatoHandler = useGetGelatoHandler()

  const [gasPrice, setGasPrice] = useState(1000)

  const handlePlaceOrder = async () => {
    if (!account) return

    const signer = await library?.getSigner(account).connectUnchecked()

    const gelatoLimitOrders = new GelatoLimitOrders(chainId as ChainId, signer, gelatoHandler)

    await Promise.all(
      limitOrders.map(async order => {
        const submitOrder = async () => {
          const tx = await gelatoLimitOrders.submitLimitOrder(
            order.inputToken,
            order.outputToken,
            order.inputAmount,
            order.minReturn,
            true,
            { gasPrice: gasPrice * 10 ** 9 }
          )

          await tx.wait()
        }

        try {
          await submitOrder()
        } catch (e) {
          const approved = await gelatoLimitOrders.approveTokenAmount(order.inputToken, MaxUint256).catch(() => {
            return false
          })

          if (approved) {
            await submitOrder()
          }
        }
      })
    )
  }

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleAmountValueInput = useCallback(
    (amount: string, field: Field) => {
      onUserInput(field, amount)
    },
    [onUserInput]
  )

  const handleHalfInput = useCallback(() => {
    let half = 0
    const maxAmount = maxAmountInput?.toExact()
    if (maxAmount) {
      half = parseFloat(maxAmount) / 2
    }
    onUserInput(Field.INPUT, `${half}`)
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const [invertedUserPrice, setInvertedUserPrice] = useState('')

  const handleAboveMarketPercentageInput = (value: string) => {
    const executionPrice = trade?.executionPrice

    const formattedPrice = executionPrice?.toSignificant(6)
    const formattedInvertedPrice = executionPrice?.invert().toSignificant(6)

    if (value) {
      if (formattedPrice && formattedInvertedPrice) {
        const percentageToAmount = ((100 + parseFloat(value)) / 100) * parseFloat(formattedPrice)
        const percentageToInvertedAmount = ((100 + parseFloat(value)) / 100) * parseFloat(formattedInvertedPrice)

        setInvertedUserPrice(percentageToInvertedAmount.toFixed(6))
        onUserLimitInput(`${percentageToAmount.toFixed(6)}`, value)
        setLimitChanged(true)
      }
    } else {
      formattedPrice && onUserLimitInput(formattedPrice, '')
      formattedInvertedPrice && setInvertedUserPrice(formattedInvertedPrice)
    }
  }

  const handlePriceInput = (value: string) => {
    const executionPrice = trade?.executionPrice

    const formattedPrice = executionPrice?.toSignificant(6)

    setLimitChanged(true)

    if (!value || parseFloat(value) === 0) {
      return onUserLimitInput(value, '')
    }

    if (formattedPrice) {
      const limitDifference = parseFloat(value) - parseFloat(formattedPrice)

      const currentMarketDiff = Big(limitDifference)
        .div(formattedPrice)
        .mul(100)

      const newInvertedPrice = Big(1).div(value)

      setInvertedUserPrice(newInvertedPrice.toString())
      onUserLimitInput(value, `${currentMarketDiff.toFixed(4)}`)
    }
  }

  const handleInvertedPriceInput = (value: string) => {
    const executionPrice = trade?.executionPrice

    const formattedPrice = executionPrice?.toSignificant(6)

    setLimitChanged(true)

    if (!value || parseFloat(value) === 0) {
      return setInvertedUserPrice(value)
    }

    if (formattedPrice) {
      const notInvertedPrice = Big(1)
        .div(value)
        .toNumber()

      const limitDifference = notInvertedPrice - parseFloat(formattedPrice)
      const currentMarketDiff = Big(limitDifference)
        .div(formattedPrice)
        .mul(100)

      setInvertedUserPrice(value)
      onUserLimitInput(String(notInvertedPrice), `${currentMarketDiff.toFixed(4)}`)
    }
  }

  const showInputValue = (current: string) => {
    if (parseFloat(limitValue)) {
      return formattedAmounts.INPUT
    }

    return current
  }

  const showOutputValue = (current: string) => {
    if (parseFloat(limitValue)) {
      if (!formattedAmounts.INPUT) {
        return ''
      }

      return Big(formattedAmounts.INPUT)
        .mul(limitValue)
        .toString()
    }

    return current
  }

  const inputCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies[Field.INPUT] ?? undefined)
  const outputCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies[Field.OUTPUT] ?? undefined)

  const executionPair = `${trade?.executionPrice?.baseCurrency?.symbol ?? '--'} / ${trade?.executionPrice?.quoteCurrency
    ?.symbol ?? '--'}`

  const executionPairInverted = `${trade?.executionPrice?.invert()?.baseCurrency?.symbol ??
    '--'} / ${trade?.executionPrice?.invert()?.quoteCurrency?.symbol ?? '--'}`

  const formattedPrice = trade?.executionPrice?.toSignificant(6)

  const invertedFormattedPrice = trade?.executionPrice?.invert()?.toSignificant(6)

  const invertedPriceValue = limitChanged ? invertedUserPrice : invertedFormattedPrice

  const priceValue = limitChanged ? limitValue : formattedPrice

  return (
    <div className={`dark:text-lightGray dark:bg-bgBlack border-2 flex-grow px-6 py-4 bg-white ml-2`}>
      <div>
        <button className="border-2 px-8 py-3 mr-6" onClick={batchAddTokens}>
          Add tokens to wallet
        </button>
      </div>
      <div className="grid grid-cols-2 w-100 gap-5">
        <div className="">
          <p>From</p>
          <CurrencyInputPanel
            hideInput={true}
            label={independentField === Field.OUTPUT && trade ? 'From (estimated)' : 'From'}
            value={showInputValue(formattedAmounts[Field.INPUT])}
            showMaxButton={true}
            currency={currencies[Field.INPUT]}
            onMax={handleMaxInput}
            onHalf={handleHalfInput}
            onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[Field.OUTPUT]}
            id="swap-currency-input"
            isButton={false}
          />
          <p className="text-xs font-thin mt-1">Bal: {inputCurrencyBalance?.toSignificant(6) ?? '--'}</p>
        </div>
        <div className="">
          <p>TO</p>
          <CurrencyInputPanel
            hideInput={true}
            value={showOutputValue(formattedAmounts[Field.OUTPUT])}
            label={independentField === Field.INPUT && trade ? 'To (estimated)' : 'To'}
            showMaxButton={false}
            currency={currencies[Field.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            id="swap-currency-output"
            isButton={true}
            disableInput
          />
          <p className="text-xs font-thin mt-1">Bal: {outputCurrencyBalance?.toSignificant(6) ?? '--'}</p>
        </div>
      </div>
      <div className="flex w-100 mt-2 items-center">
        <div className="w-2/6">
          <p>Input</p>
        </div>
        <div className="flex-grow">
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
            <NumericalInput
              className="bg-bgColor dark:text-lightGray dark:bg-bgBlack text-md token-amount-input token-amount-value"
              value={showInputValue(formattedAmounts[Field.INPUT])}
              onUserInput={handleTypeInput}
            />
          </div>
        </div>
      </div>
      <div className="flex w-100 mt-2 items-center">
        <div className="w-2/6">
          <p></p>
        </div>
        <div className="flex-grow grid grid-cols-4 gap-2 font-semibold">
          <div
            onClick={() => handleAmountValueInput('2000', Field.INPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">2000</p>
          </div>
          <div
            onClick={() => handleAmountValueInput('3000', Field.INPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">3000</p>
          </div>
          <div
            onClick={() => handleAmountValueInput('4000', Field.INPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">4000 </p>
          </div>
          <div
            onClick={() => handleAmountValueInput('5000', Field.INPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">5000</p>
          </div>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>Output</p>
        </div>
        <div className="flex-grow">
          <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
            <NumericalInput
              className="bg-bgColor dark:text-lightGray dark:bg-bgBlack text-md token-amount-input token-amount-value"
              value={showOutputValue(formattedAmounts[Field.OUTPUT])}
              onUserInput={handleTypeOutput}
            />
          </div>
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p></p>
        </div>
        <div className="flex-grow grid grid-cols-4 gap-2 font-semibold">
          <div
            onClick={() => handleAmountValueInput('2000', Field.OUTPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">2000</p>
          </div>
          <div
            onClick={() => handleAmountValueInput('3000', Field.OUTPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">3000</p>
          </div>
          <div
            onClick={() => handleAmountValueInput('4000', Field.OUTPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">4000 </p>
          </div>
          <div
            onClick={() => handleAmountValueInput('5000', Field.OUTPUT)}
            className="bg-bgColor dark:bg-bgBlack border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2"
          >
            <p className="text-gray-400 text-xs">5000</p>
          </div>
        </div>
      </div>
      <div className="flex w-100 mt-2 items-center">
        <div className="w-2/6">
          <p>{executionPair}</p>
        </div>
        <div className="flex-grow dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            isDisabled={!trade}
            className="bg-bgColor dark:bg-bgBlack dark:text-white text-md token-amount-input token-amount-value"
            value={priceValue ?? '0'}
            onUserInput={handlePriceInput}
          />
        </div>
      </div>
      <div className="flex w-100 mt-2 items-center">
        <div className="w-2/6">
          <p>{executionPairInverted}</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            isDisabled={!trade}
            className="bg-bgColor dark:bg-bgBlack dark:text-white text-md token-amount-input token-amount-value"
            value={invertedPriceValue ?? '0'}
            onUserInput={handleInvertedPriceInput}
          />
        </div>
      </div>
      {/* <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>Min Recieve</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p>18</p>
        </div>
      </div> */}
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/5">
          <p>Above Market %</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            className="bg-bgColor dark:bg-bgBlack dark:text-lightGray text-md token-amount-input token-amount-value"
            value={percentage ?? '0'}
            onUserInput={handleAboveMarketPercentageInput}
          />
        </div>
      </div>
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/6">
          <p>Orders</p>
        </div>
        <div className="flex-grow bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            className="bg-bgColor dark:bg-bgBlack dark:text-lightGray text-md token-amount-input token-amount-value"
            value={ordersAmount ?? 1}
            onUserInput={onUserOrdersAmountInput}
          />
        </div>
      </div>
      {/* <div className="flex w-100  mt-2 items-center">
        <div className="w-2/5">
          <NumericalInput
            className="bg-bgColor dark:bg-bgBlack dark:text-lightGray text-md token-amount-input token-amount-value"
            value={deviation ?? 0}
            onUserInput={onUserDeviationInput}
          />
        </div>
      </div> */}
      <div className="flex w-100  mt-2 items-center">
        <div className="w-2/5">
          <p>% Deviation</p>
        </div>
        {/* <div className="flex-grow flex justify-end items-center h-10 px-2 text-right font-semibold">
          <p className="font-thin">Randomise</p>
          <input
            className="text-indigo-500 w-5 h-5 ml-2 focus:ring-indigo-400 focus:ring-opacity-25 border border-gray-300 rounded"
            type="checkbox"
          />
        </div> */}
      </div>
      <div className="flex w-100 mt-2 items-center font-semibold">
        <div className="w-2/4 bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            className="bg-bgColor dark:text-lightGray dark:bg-bgBlack text-md token-amount-input token-amount-value"
            value={deviation ?? '0'}
            onUserInput={onUserDeviationInput}
          />
        </div>
      </div>
      <div className="w-3/5 mt-2">
        <p>Gelato handler</p>
      </div>
      <div className="flex w-full mt-2 items-center font-semibold">
        <div className="w-full flex items-center h-10 font-semibold">
          <ReactSelect
            styles={{
              option: provided => ({
                ...provided,
                color: '#000000'
              })
            }}
            value={{ value: gelatoHandler, label: gelatoHandler }}
            options={[
              { value: 'tombswap', label: 'tombswap' },
              { value: 'spookyswap', label: 'spookyswap' }
            ]}
            onChange={option => {
              option && setGelatoHandler(option.value as Handler)
            }}
          />
        </div>
      </div>
      <div className="w-3/5 mt-2">
        <p>Gas price</p>
      </div>
      <div className="flex-grow grid grid-cols-4 gap-2 font-semibold">
        <div
          onClick={() => setGasPrice(400)}
          className={clsx('border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2', {
            'dark:bg-green-300': gasPrice === 400
          })}
        >
          <p className="text-gray-400 text-xs">400</p>
        </div>
        <div
          onClick={() => setGasPrice(800)}
          className={clsx('border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2', {
            'dark:bg-green-300': gasPrice === 800
          })}
        >
          <p className="text-gray-400 text-xs">800</p>
        </div>
        <div
          onClick={() => setGasPrice(1000)}
          className={clsx('border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2', {
            'dark:bg-green-300': gasPrice === 1000
          })}
        >
          <p className="text-gray-400 text-xs">1000</p>
        </div>
        <div
          onClick={() => setGasPrice(1500)}
          className={clsx('border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2', {
            'dark:bg-green-300': gasPrice === 1500
          })}
        >
          <p className="text-gray-400 text-xs">1500</p>
        </div>
        <div
          onClick={() => setGasPrice(2000)}
          className={clsx('border-2 border-white cursor-pointer flex justify-end items-center h-10 px-2', {
            'dark:bg-green-300': gasPrice === 2000
          })}
        >
          <p className="text-gray-400 text-xs">2000</p>
        </div>
        <div className="bg-bgColor dark:bg-bgBlack col-span-3 border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
          <NumericalInput
            placeholder="Custom gas price"
            className="bg-bgColor dark:text-white flex dark:bg-bgBlack text-md token-amount-input token-amount-value"
            value={gasPrice}
            onUserInput={val => setGasPrice(Number(val))}
          />
        </div>
      </div>
      <div className="flex mt-4">
        <div className="border-2 px-8 py-3 mr-6">Reset</div>
        <div
          onClick={handlePlaceOrder}
          className="cursor-pointer border-2 border-gray-700 bg-bgColor dark:bg-border dark:text-black flex-grow flex items-center justify-center hover:bg-gray-400"
        >
          Confirm All Orders
        </div>
      </div>
    </div>
  )
}

//465: {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
