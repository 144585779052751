import React, { useState, useCallback, useEffect } from "react";
import darklogo from "../../assets/svg/dark_logo.svg";
import lightlogo from "../../assets/svg/light_logo.svg";
import lightColor from "../../assets/svg/lightColor.svg";
import darkColor from "../../assets/svg/darkColor.svg";
import { useSelector } from "react-redux";
// import Modal from "../Modal";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { ChevronDownIcon } from "@heroicons/react/solid"
import {
  useDarkModeManager,
  useWhiteListManager,
  useIsWhiteList,
  useWhiteListDeleter,
  useLocalWhiteListCleaner,
  useBotWhiteListManager,
  useLocalBotWhiteListCleaner,
  useBotWhiteListDeleter,
  useIsBotWhiteList
} from "../../state/user/hooks";
import { Input as AlphaNumericInput } from "../AlphaNumericInput";

import { supabase } from '../../utils/Supabase';
import { RealtimeSubscription, SupabaseRealtimePayload } from "@supabase/supabase-js";
import { url } from "inspector";

export default function SettingDialog(props: any) {
  const [openTab, setOpenTab] = useState(2);
  const { isSettingOpen, setIsSettingOpen } = props;
  const [darkMode, toggleSetDarkMode] = useDarkModeManager();
  const addWhiteList = useWhiteListManager();
  const clearLocalWhiteList = useLocalWhiteListCleaner();
  const deleteWhiteList = useWhiteListDeleter();
  const whiteList = useIsWhiteList();

  const addBotWhiteList = useBotWhiteListManager();
  const clearLocalBotWhiteList = useLocalBotWhiteListCleaner();
  const deleteBotWhiteList = useBotWhiteListDeleter();
  const botWhiteList = useIsBotWhiteList();

  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  let QTT_WhitelistedAddressSubscription: RealtimeSubscription;
  let QTT_WhitelistedBotsSubscription: RealtimeSubscription;

  async function addToList() {
    if (address == "" || name == "") return;

    setAddress("");
    setName("");

    try {
      const { error } = await supabase
        .from('QTT.WhitelistedAddress')
        .upsert({ name: name, address: address }, { onConflict: 'address' })

      if (error) {
        console.log('Realtime update error', error)
      }
      setIsEditMode(false);
    } catch (err) {
      console.log('Realtime update exception', err)
    }
  }

  const updateNotification = async (address: any, notiUrl: any) => {
    // todo: when user selects the alert change to db
  }


  function handleSupabaseWhitelistedBotsUpdate(payload: SupabaseRealtimePayload<any>) {
    let type = payload.eventType;

    switch (type) {
      case 'DELETE':
        const { old: deletedData } = payload;
        deleteBotWhiteList(deletedData.id);
        break;
      case 'INSERT':
      case 'UPDATE':
        const { new: newData } = payload;
        const { id, name, owner, description, contract_address, bot_address } = newData;
        addBotWhiteList(id, name, owner, description, contract_address, bot_address);

        break;
      default:
        return;
    }
  }

  async function fetchLatestWhitelistBotData() {
    try {
      let { data, error } = await supabase
        .from('QTT.WhitelistedBots')
        .select('*')

      if (error) {
        console.log('data fetch exception', error);
      }

      if (data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            addBotWhiteList(data[i].index, data[i].name, data[i].owner, data[i].description, data[i].contract_address, data[i].bot_address);
          }
        }
      }
    } catch (err) {
      console.log('data fetch exception', err);
    }

  }


  async function deleteWhitelistedBot(id: number) {
    try {
      const { error } = await supabase
        .from('QTT.WhitelistedBots')
        .delete()
        .eq('id', id)

      if (error) {
        console.log('Realtime delete error', error)
      }
    } catch (err) {
      console.log('Realtime delete exception', err)
    }
  }

  function handleSupabaseAddressUpdate(payload: SupabaseRealtimePayload<any>) {
    let type = payload.eventType;
    let index = whiteList?.length ? `${whiteList.length}` : '0';

    switch (type) {
      case 'DELETE':
        const { old: deletedData } = payload;
        deleteWhiteList(deletedData.address, deletedData.name);
        break;
      case 'INSERT':
      case 'UPDATE':
        const { new: newData } = payload;
        addWhiteList(parseInt(index), newData.address, newData.name);
        break;
      default:
        return;
    }
  }

  async function deleteAddress(address: string, name: string) {
    try {
      const { error } = await supabase
        .from('QTT.WhitelistedAddress')
        .delete()
        .eq('name', name)
        .eq('address', address)

      if (error) {
        console.log('Realtime delete error', error)
      }
      setIsEditMode(false);
      setAddress('');
      setName('');
    } catch (err) {
      console.log('Realtime delete exception', err)
    }
  }

  async function editAddress(address: string, name: string) {
    setIsEditMode(true);
    setAddress(address);
    setName(whiteList[address]?.name);
  }

  async function fetchLatestWhitelistData() {
    try {
      let { data, error } = await supabase
        .from('QTT.WhitelistedAddress')
        .select('*')

      if (error) {
        console.log('data fetch exception', error);
      }

      if (data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            addWhiteList(i, data[i].address, data[i].name);
          }
        }
      }
    } catch (err) {
      console.log('data fetch exception', err);
    }

  }

  function subscribeToSupabaseRealtime() {
    if (!QTT_WhitelistedAddressSubscription) {
      QTT_WhitelistedAddressSubscription = supabase
        .from('QTT.WhitelistedAddress')
        .on('*', payload => {
          handleSupabaseAddressUpdate(payload);
        })
        .subscribe()
    }
    if (!QTT_WhitelistedBotsSubscription) {
      QTT_WhitelistedBotsSubscription = supabase
        .from('QTT.WhitelistedBots')
        .on('*', payload => {
          handleSupabaseWhitelistedBotsUpdate(payload);
        })
        .subscribe()
    }
  }

  const playSound = (e: any) => {
    try {
      console.log(e.target.value);
      const audio = new Audio(e.target.value);
      audio.play();
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    clearLocalWhiteList();
    clearLocalBotWhiteList();
    subscribeToSupabaseRealtime();
    fetchLatestWhitelistData();
    fetchLatestWhitelistBotData();
  }, [])

  return (
    <DialogOverlay
      className="z-50"
      isOpen={isSettingOpen}
      onDismiss={() => setIsSettingOpen(!isSettingOpen)}
    >
      <DialogContent
        aria-label="setting dialog content"
        className="dark:bg-bgBlack dark:text-lightGray border-2 justify-center pl-0 pr-0"
        style={{ width: "1140px", height: "650px" }}
      >
        <div className="flex px-8">
          <div className="w-1/2 flex justify-start font-normal text-3xl dark:text-lightGray text-black">
            Settings
          </div>

          <div className="w-1/2 flex justify-end pr-5 gap-x-14 mt-4">
            <div className="flex">
              <a href="#_" className="mr-4" onClick={async () => { await supabase.auth.signOut() }}>Logout</a>
            </div>
            <div className="flex">
              <span className="mr-4">Orientation</span>
              <span className={`cursor-pointer`}>
                <img
                  className="mx-2"
                  width={25}
                  height={15}
                  src={darkColor}
                  alt="Light Icon"
                />
              </span>
              <span className={` cursor-pointer`}>
                <img
                  className="mx-2"
                  width={14}
                  height={20}
                  src={lightColor}
                  alt="Light Icon"
                />
              </span>
            </div>
            <div className="flex">
              <span className="mr-4">Mode</span>
              <span
                className={`${darkMode ? "cursor-not-allowed" : ""
                  } cursor-pointer`}
                onClick={() => toggleSetDarkMode()}
              >
                <img
                  className="mx-2"
                  width={13}
                  height={20}
                  src={darklogo}
                  alt="Dark Icon"
                />
              </span>
              <span
                className={`${darkMode ? "" : "cursor-not-allowed"
                  } cursor-pointer`}
                onClick={() => toggleSetDarkMode()}
              >
                <img
                  className="mx-2"
                  width={20}
                  height={20}
                  src={lightlogo}
                  alt="Light Icon"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 w-3/4 mx-8 gap-2 mt-8 ">
          {/* <div
            className={`cursor-pointer flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 1 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(1)}
          >
            <p>Pricing Pairs</p>
          </div> */}
          <div
            className={`cursor-pointer flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 2 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(2)}
          >
            <p>Whitelist</p>
          </div>
          <div
            className={`cursor-pointer flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 4 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(4)}
          >
            <p>Bot WhiteListing</p>
          </div>
          <div
            className={`cursor-pointer flex w-48 justify-center pt-3 border-t border-[#CECECE] rounded-t-xl border-l border-r pb-2 hover:bg-bgGray dashboard-tab-border ${openTab == 3 ? "bg-white dark:bg-bgGray" : ""
              }`}
            onClick={() => setOpenTab(3)}
          >
            <p>Notifications</p>
          </div>
        </div>
        {/* {openTab === 1 && (
          <>
            <div className="pl-8 w-full border-t border-[#CECECE] pt-7">
              <div className="h-full w-32">
                <div className="ml-2 text-xl mb-5">Pair</div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest	border border-[#CECECE">
                  TOMB/FTM
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TSHARE/FTM
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TOMB/MAI
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TSHARE/MAI
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  TOMB/BASED
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  BASED/MAI
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest	 border border-[#CECECE">
                  TOMB/TBOND
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest border border-[#CECECE">
                  MAI/USDC
                </div>
                <div className="my-3 w-44 px-2 py-1 text-l tracking-widest text-center border border-[#CECECE">
                  +
                </div>
              </div>

              <div className="h-full"></div>
            </div>
          </>
        )} */}
        {openTab === 2 && (
          <>
            <div className="flex border-t w-full mt-7 pl-5">
              <div className="flex w-100 mt-5 items-center">
                <div className="flex gap-x-5 ite">
                  <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
                    <AlphaNumericInput
                      className="w-96 flex-grow bg-bgColor dark:text-lightGray dark:bg-bgBlack text-base token-amount-input token-amount-value"
                      value={name}
                      onUserInput={(val) => {
                        setName(val);
                      }}
                      isDisabled={false}
                      placeholder="Name"
                    />
                  </div>

                  <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
                    <AlphaNumericInput
                      className={`w-96 bg-bgColor dark:text-lightGray dark:bg-bgBlack text-base token-amount-input token-amount-value ${isEditMode ? 'cursor-not-allowed' : 'cursor-text'}`}
                      value={address}
                      onUserInput={(val) => {
                        setAddress(val);
                      }}
                      isDisabled={isEditMode}
                      placeholder="Wallet Address"
                    />
                  </div>

                  <div className="w-40 border-2 border-gray-700 bg-bgColor dark:bg-border dark:text-black flex justify-center items-center h-10 px-2 text-center font-semibold cursor-pointer"
                    onClick={() => addToList()}
                  >
                    {isEditMode ? 'Save' : 'Add'}
                  </div>

                </div>
              </div>
            </div>

            <div className="flex w-full mt-7" style={{ height: '357px' }}>
              <div
                className="ml-5 flex-grow mt-7 overflow-y-auto mr-2"
              >
                <div className="grid grid-cols-2 border px-2 font-semibold text-center">
                  <div className="py-2 border-r">Wallet Name</div>
                  <div className="py-2">Wallet Address</div>
                </div>
                {whiteList && (
                  <>
                    {Object.keys(whiteList).map((address) => (
                      <div key={address} className="grid grid-cols-2 border border-t-0 px-2 ">
                        <div className="py-2 border-r">{`${whiteList[address]?.name}`}</div>
                        <div className="py-2 ml-2">
                          {`${address}`}
                          <a
                            href="#_"
                            className="ml-2 text-red-500"
                            onClick={() => { deleteAddress(address, whiteList[address]?.name) }}
                          >
                            Delete
                          </a> |
                          <a
                            href="#_"
                            className="ml-2 text-blue-500"
                            onClick={() => { editAddress(address, whiteList[address]?.name) }}
                          >
                            Edit
                          </a>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {openTab === 3 &&
          <div className="flex w-full justify-center text-center mt-20">

            <div className="flex w-4/5 header mx-20 justify-center text-center max-h-96	overflow-y-auto">
              <table className="relative table-auto w-full border">
                <thead className="">
                  <tr className="">
                    <th className="sticky top-0 py-2 w-1/5 border border-slate-300 bg-bgColor dark:bg-bgBlack">Activate</th>
                    <th className="sticky top-0 py-2 border border-slate-300 bg-bgColor dark:bg-bgBlack">Name</th>
                    <th className="sticky top-0 py-2 border border-slate-300 bg-bgColor dark:bg-bgBlack">Sound</th>
                  </tr>
                </thead>
                <tbody>

                  {Object.keys(whiteList).map((address) => {
                    return (
                      <tr key={address} >
                        <td className=" border border-slate-300"><input type="checkbox" /></td>
                        <td className=" border border-slate-300">{whiteList[address].name}</td>
                        <td className=" border border-slate-300 justify-center text-center">
                          <select onChange={(e) => playSound(e)}
                            className="form-select appearance-none text-center justify-center hover 
                            block dark:text-lightGray dark:bg-bgBlack w-1/2 transition rounded bg-clip-padding bg-no-repeat" name="" id="">
                            <option value={`${process.env.REACT_APP_SOUND_URL}/alert-sound-1.mp3`}>Alert Sound 1</option>
                            <option value={`${process.env.REACT_APP_SOUND_URL}/alert-sound-2.mp3`}>Alert Sound 2</option>
                            <option value={`${process.env.REACT_APP_SOUND_URL}/alert-sound-3.mp3`}>Alert Sound 3</option>
                            <option value={`${process.env.REACT_APP_SOUND_URL}/alert-sound-4.mp3`}>Alert Sound 4</option>
                            <option value={`${process.env.REACT_APP_SOUND_URL}/alert-sound-5.mp3`}>Alert Sound 5</option>
                          </select>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>

          </div>
        }

        {openTab === 4 && (
          <>
            <div className="flex border-t w-full mt-7 pl-5">
              <div className="flex w-100 mt-5 items-center">
                <div className="flex gap-x-5 ite">
                  <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
                    <AlphaNumericInput
                      className="w-96 flex-grow bg-bgColor dark:text-lightGray dark:bg-bgBlack text-base token-amount-input token-amount-value"
                      value={name}
                      onUserInput={(val) => {
                        setName(val);
                      }}
                      isDisabled={false}
                      placeholder="Name"
                    />
                  </div>

                  <div className="bg-bgColor dark:bg-bgBlack border-2 border-white flex justify-end items-center h-10 px-2 text-right font-semibold">
                    <AlphaNumericInput
                      className={`w-96 bg-bgColor dark:text-lightGray dark:bg-bgBlack text-base token-amount-input token-amount-value ${isEditMode ? 'cursor-not-allowed' : 'cursor-text'}`}
                      value={address}
                      onUserInput={(val) => {
                        setAddress(val);
                      }}
                      isDisabled={isEditMode}
                      placeholder="Bot Contract"
                    />
                  </div>

                  <div className="w-40 border-2 border-gray-700 bg-bgColor dark:bg-border dark:text-black flex justify-center items-center h-10 px-2 text-center font-semibold cursor-pointer"
                    onClick={() => alert("Feature in progress...")}
                  >
                    {isEditMode ? 'Save' : 'Add'}
                  </div>

                </div>
              </div>
            </div>

            <div className="flex w-full mt-7" style={{ height: '357px' }}>
              <div
                className="ml-5 flex-grow mt-7 overflow-y-auto mr-2"
              >
                <div className="grid grid-cols-2 border px-2 font-semibold text-center">
                  <div className="py-2 border-r">Wallet Name</div>
                  <div className="py-2">Wallet Address</div>
                </div>
                {whiteList && (
                  <>
                    {Object.keys(whiteList).map((address) => (
                      <div key={address} className="grid grid-cols-2 border border-t-0 px-2 ">
                        <div className="py-2 border-r">{`${whiteList[address]?.name}`}</div>
                        <div className="py-2 ml-2">
                          {`${address}`}
                          <a
                            href="#_"
                            className="ml-2 text-red-500"
                            onClick={() => { deleteAddress(address, whiteList[address]?.name) }}
                          >
                            Delete
                          </a> |
                          <a
                            href="#_"
                            className="ml-2 text-blue-500"
                            onClick={() => { editAddress(address, whiteList[address]?.name) }}
                          >
                            Edit
                          </a>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </>
        )}

      </DialogContent>
    </DialogOverlay>
  );
}
