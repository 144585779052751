import React from "react";
import { Link, useLocation } from 'react-router-dom'
// import { isMobile } from 'react-device-detect'
import Web3Status from "../Web3Status";
import { useIsDarkMode } from "../../state/user/hooks";
import TombSwap from "../../assets/svg/logo.svg";
import SettingsLight from "../../assets/svg/setting-light.svg";
import SettingsDark from "../../assets/svg/setting-dark.svg";
import SwapDark from "../../assets/svg/dark_swap.svg";
import SwapLight from "../../assets/svg/swap.svg";
import OrderDark from "../../assets/svg/dark_order.svg";
import OrderLight from "../../assets/svg/order.svg";
import BalanceLight from '../../assets/svg/balance_dark.svg';
import BalanceDark from '../../assets/svg/balance.svg';
import BotLight from '../../assets/svg/bots_dark.svg';
import BotDark from '../../assets/svg/bot.svg';
import { useDarkModeManager } from "../../state/user/hooks";

export default function SideNav(props: any) {
  const { pathname } = useLocation()

  const { isSettingOpen, setIsSettingOpen } = props;
  const isDark = useIsDarkMode();
  const [darkMode] = useDarkModeManager();
  const navlinks = [
    {name:'Swap', link:'/swap', icon:SwapLight, iconDark: SwapDark},
    {name:'Orders', link:'/orders', icon:OrderLight, iconDark: OrderDark},
    {name:'Balance', link:'/accounts', icon:BalanceLight, iconDark: BalanceDark},
    {name: 'Bots', link:'/bots', icon:BotLight, iconDark: BotDark},
    {
      name:'Settings', link:'/settings', icon:SettingsLight, iconDark:SettingsDark, 
      isOnclick:true, onClickHandler:setIsSettingOpen, changingValue:isSettingOpen
    }
  ]

  return (
    <nav className="flex-1 space-y-1 border-r border-white divide-y">
        <div className="">
          {
            navlinks.map( nav => {

              if(nav.isOnclick){  
                return <OnclickNavigation key={nav.name} onClickHandler={nav.onClickHandler}
                        booleanValue={nav.changingValue}
                        isDark={isDark}
                        nav={nav}
                 />
              }

              return (
                <Link key={nav.name} to={nav.link}>
                  <div
                    className={`border border-r-0 border-gray-300 py-10 cursor-pointer text-center ${nav.link=== pathname? "bg-bgColor dark:bg-bgGray" : ""
                      }`}>
                    {
                      <img className="mb-2 mx-auto" src={darkMode ? nav.iconDark : nav.icon} alt={`${nav.name} Icon`} />
                    }
                    <span>{nav.name}</span>
                  </div>
                </Link>
              )
            } )
          }
        </div>
    </nav>
  );
}

const OnclickNavigation = ({onClickHandler, booleanValue, isDark, nav}:{onClickHandler:any, booleanValue:any, isDark:any, nav:any}) => {
  return (
          <div
            className="border border-r-0 py-10 border-gray-300 px-2 cursor-pointer text-center"
            onClick={() => onClickHandler(!booleanValue)}
          >
            <img className="mx-auto mb-2" src={isDark ? nav.iconDark : nav.icon} alt={nav.name} />
            <p>{nav.name}</p>
          </div>
  )
}
